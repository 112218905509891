define('app/cookieWall',['jquery', 'lodash', 'js.cookie'], function ($, _, Cookies) {
    'use strict';

    return {
        init: function init() {
            try {
                console.log('Start initializing cookieWall');

                var COOKIE_CW = 'cookieWall';
                var EXP_DAYS = 365;
                var PATH_STARTS_WITH = "/cookies";

                var wallState = Cookies.getJSON(COOKIE_CW);

                if (_.isEmpty(wallState)) {
                    var initialState = {
                        hideWall: false,
                        allowAdvertisingCookies: false,
                        allowTrackingCookies: false
                    };
                    Cookies.set(COOKIE_CW, initialState, { expires: EXP_DAYS });
                    wallState = initialState;
                }

                if (wallState.hideWall) {
                    $('#cookiewall').hide();
                    $( "body" ).removeClass( "showCookieWall" );
                } else {
                    $('#cookiewall').show();
                    $( "body" ).addClass( "showCookieWall" );
                }

                if (_.startsWith(window.location.pathname, PATH_STARTS_WITH)) {
                    $('#cookiewall').show();
                    $( "body" ).removeClass( "showCookieWall" );
                }

                if (wallState.allowAdvertisingCookies) {
                    $('#cookiewall input[name="allowAdvertisingCookies"]').attr('checked', true);
                }
                if (wallState.allowTrackingCookies) {
                    $('#cookiewall input[name="allowTrackingCookies"]').attr('checked', true);
                }

                $('#cookiewall input.allowCookieToggler').change(function() {
                    if(this.checked) {
                        wallState[this.name] = true;
                    } else {
                        wallState[this.name] = false;
                    }
                    Cookies.set(COOKIE_CW, wallState, { expires: EXP_DAYS });
                });

                $( "#cookiewall .saveCookieSettings" ).click(function() {
                    wallState.hideWall = true;
                    Cookies.set(COOKIE_CW, wallState, { expires: EXP_DAYS });
                    if (!_.startsWith(window.location.pathname, PATH_STARTS_WITH)) {
                        window.location.reload();
                    }
                });

                $( "#cookiewall .rejectCookieSettings" ).click(function() {
                    wallState.hideWall = true;
                    wallState.allowAdvertisingCookies = false;
                    wallState.allowTrackingCookies = false;
                    Cookies.set(COOKIE_CW, wallState, { expires: EXP_DAYS });
                    if (!_.startsWith(window.location.pathname, PATH_STARTS_WITH)) {
                        window.location.reload();
                    }
                });

                $( "#cookiewall .acceptCookieSettings" ).click(function() {
                    wallState.hideWall = true;
                    wallState.allowAdvertisingCookies = true;
                    wallState.allowTrackingCookies = true;
                    Cookies.set(COOKIE_CW, wallState, { expires: EXP_DAYS });
                    if (!_.startsWith(window.location.pathname, PATH_STARTS_WITH)) {
                        window.location.reload();
                    }
                });


            } catch (e) {
                console.warn('error in cookieWall process', e);
            }
        }
    };
});
