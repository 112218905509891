define('app/friendForm',[
    'jquery',
    'lodash',
    atob('Y3J5cHRvLWpzL2htYWMtc2hhMjU2')
], function ($, _, f) {
    'use strict';

    var generalFormError = 'Er is iets misgegaan. Het formulier is niet verzonden. <a href="#" class="linkcolor" onclick="parent.location.reload();">Probeer het nogmaals</a>.';

    var collectField = function (v, defaultValue) {
        if(_.isUndefined(defaultValue)) {
            defaultValue = null;
        }
        if (!_.isEmpty(v)) {
            var newV = _.trim(v);
            if (!_.isEmpty(newV)) {
                return newV;
            } else {
                return defaultValue;
            }
        } else {
            return defaultValue;
        }
    };

    var outputMessage = function (msg) {
        $('#friendFormDiv').html('<p>' + msg + '</p>');
    };

    var handleFormSubmission = function (e) {
        e.preventDefault();

        var form = $(this);
        var formFields = form.serializeArray();

        var email = collectField((_.find(formFields, {name: 'email'}) || {}).value);
        var cid = collectField(form.data('cid'));

        var chref = collectField(form.data('chref'), '/programma');
        var chrefMsg = collectField(form.data('chrefMsg'), 'Klik HIER om verder te gaan');
        var chrefExtraMsg = collectField(form.data('chrefExtraMsg'), '');

        if (!_.isEmpty(email) && !_.isEmpty(cid)) {
            $.ajax({
                type: 'GET',
                url: '/api/friend-token' + atob("P2g9") + f(email+cid, atob("NTliZWFmY2M3OTk4NGU5MmFkNTRkNzdlMTA2YmQ3NDM=")) + '&email=' + encodeURIComponent(email) + '&cid=' + encodeURIComponent(cid),
                dataType: 'json',
                success: function (result) {
                    if (_.isObject(result) && _.isString(result.token)) {
                        if (!_.isEmpty(result.token)) {
                            var msg =
                            '<h2>Gelukt!</h2><p><a class="btn btn-cta btn-cta-primary btn-fullwidth" href="' + chref +
                            '?ct=' + result.token + '" style="white-space: normal;">' + chrefMsg + '</a></p>' +
                            '<p class="margin-top-20px">' + chrefExtraMsg + '</p>';
                            outputMessage(msg);
                        } else {
                            outputMessage(
                            '<p>Het lijkt erop dat je voor komend seizoen geen Vriend bent van de Deventer Schouwburg. Ben je wel Vriend? Neem dan contact met ons op via 0900 - 4000 300 (lokaal tarief).</p>' +
                            '<a class="btn btn-cta btn-cta-primary btn-fullwidth" onclick="parent.location.reload();">Probeer het opnieuw</a>');
                        }
                    } else {
                        outputMessage(generalFormError);
                    }
                },
                error: function () {
                    outputMessage(generalFormError);
                }
            });
        } else {
            outputMessage(generalFormError);
        }
    };

    return {
        init: function () {
            console.log('Start initializing friendForm');
            $('form#friendForm').on('submit', handleFormSubmission);
        }
    };
});

