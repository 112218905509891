define('app/favorites',[
    'jquery',
    'lodash'
], function ($, _) {
    'use strict';

    return {
        init: function () {

            console.log('Start initializing favorites');

            var getParsedCount = function (favCountSpan) {
                var parsedCount = 0;
                try {
                    var newParsedCount = parseInt(favCountSpan.text().replace("(", "").replace(")", ""));
                    if (!_.isNaN(newParsedCount)) {
                        parsedCount = newParsedCount;
                    }
                } catch (e) {
                }
                return parsedCount;
            };

            var updateCount = function (actionIsAdd) {
                var favCountSpan = $("#favoriteCount");
                var count = getParsedCount(favCountSpan);
                if (actionIsAdd) {
                    count += 1;
                } else {
                    count -= 1;
                }
                if (count === 0) {
                    favCountSpan.text('');
                } else if (count > 0) {
                    favCountSpan.text('(' + count + ')');
                }
            };


            var updateButtonLabel = function (actionIsAdd, favoriteToggleLabel) {
                if (actionIsAdd) {
                    favoriteToggleLabel.text(IS_FAVORITE_LABEL);
                } else {
                    favoriteToggleLabel.text(IS_NOT_FAVORITE_LABEL);
                }
            };

            var showModal = function (result) {
                $("#favoritesModalContentDiv").html(result);
                $("#modalFavorites").modal("show");
            };

            $("#modalFavorites").on('hide.bs.modal', function () {
                $("#favoritesModalContentDiv").empty();
            });

            $(".toggleFavoritesBtn").click(function () {
                var href = $(this);
                var urlPath = href.data("urlPath");

                if (!_.isEmpty(urlPath)) {
                    var favoriteToggleLabel = href.children("span.favoriteToggleLabel");
                    var currentState = favoriteToggleLabel.text();

                    if (currentState === IS_FAVORITE_LABEL || currentState == IS_NOT_FAVORITE_LABEL) {
                        var controller = ApiRouter.controllers.DeventerWishListController;

                        var actionIsAdd = currentState === IS_NOT_FAVORITE_LABEL;

                        var method = null;
                        var url = null;
                        if (currentState === IS_FAVORITE_LABEL) {
                            method = "DELETE";
                            url = controller.removeAjax(urlPath).url;
                        } else {
                            method = "POST";
                            url = controller.addAjax(urlPath).url;
                        }

                        $.ajax({
                            type: method,
                            url: url,
                            dataType: "html",
                            success: function (result) {
                                showModal(result);
                                updateCount(actionIsAdd);
                                updateButtonLabel(actionIsAdd, favoriteToggleLabel);
                            },
                            error: function (result) {
                                console.error("failed to add favorite '" + urlPath + "', error: " + JSON.stringify(result));
                            }
                        });
                    } else {
                        console.error("Unknown current state of favorites: " + currentState);
                    }

                } else {
                    console.error("url path was empty for click on favorite button");
                }
            });

            var collectJsonData = function (data, jsonData) {
                $.each(data, function () {
                    if (this.name !== "niet_invullen") {
                        jsonData[this.name] = this.value || null;
                    }
                    else if (this.value !== undefined && this.value !== null && this.value !== "") {
                        jsonData[this.name] = this.value || null;
                    }
                });
            };

            $("#modalShareWishList").on('hide.bs.modal', function () {
                $("#shareWishListContainerInput").show();
                $("#shareWishListContainerOutput").empty();
            });

            var enableSelfShare = function () {
                $("#wishListSelfShare").show();
                $("#wishListFriendShare").hide();
            };

            var enableFriendShare = function () {
                $("#wishListSelfShare").hide();
                $("#wishListFriendShare").show();
            };

            var toggleShareFormBtn = function (isEnabled) {
                var btn = $(".btnSendFormShareWishList");
                if(isEnabled) {
                    btn.show();
                } else {
                    btn.hide();
                }
            };

            $(".shareWishListBtn").click(function () {
                var href = $(this);
                var isSelfShare = href.data("shareType") === "self";
                if (isSelfShare) {
                    enableSelfShare();
                } else {
                    enableFriendShare();
                }
                _.forEach($("#modalShareWishList form"), function (f) {
                    f.reset();
                });
                toggleShareFormBtn(true);
                $("#modalShareWishList").modal("show");
            });


            $('.shareWishListForm').on('submit', function (e) {
                e.preventDefault();

                var form = $(this);
                var data = form.serializeArray();
                var url = form.attr('action');
                var outputDiv = $("#shareWishListContainerOutput");

                var jsonData = {};
                collectJsonData(data, jsonData);

                var showError = function (result) {
                    if (result) {
                        console.error("form submission failed", result);
                    }
                    toggleShareFormBtn(true);
                    outputDiv.html('<p>Er is iets misgegaan. Het formulier is niet verzonden. <a class="pinkdev" onclick="parent.location.reload();">Probeer het nogmaals</a>.</p>');
                };

                if (!$.isEmptyObject(jsonData) && url) {
                    $.ajax({
                        type: "POST",
                        url: url,
                        data: JSON.stringify(jsonData),
                        dataType: "html",
                        contentType: "application/json",
                        success: function (result) {
                            toggleShareFormBtn(false);
                            outputDiv.html(result);
                        },
                        error: showError
                    });
                }
                else {
                    showError();
                }
            });
        }
    };
});
