define('app/scroll',[
    'jquery'
], function ($) {
    'use strict';

    return {
        init: function () {
            console.log('Start initializing scroll.js');
            $(window).on('scroll load', function () {
                var wi = $(window).width();
                var header = $('#header');


                if ($(window).scrollTop() > 0 || wi <= 992) {
                    header.addClass('scrolled');
                } else {
                    if (!header.hasClass('scrolled-always')) {
                        header.removeClass('scrolled');
                    }
                }
            });
        }
    };
});
