define('app/accessibility',[],function () {
    'use strict';

    return {
        init: function init() {
            console.log('Start initializing accessibility');
            window.document.addEventListener('keydown', this.onkeydown, {once: true});

            document.getElementById("btn_goto_menu").onclick = function (e) {
                e.preventDefault();
                document.querySelector("#navbar-collapse > ul > li > a").focus();

                //hide acc menu
                document.getElementById("accessibility").style = "display: none;";
            };
            document.getElementById("btn_goto_search").onclick = function (e) {
                e.preventDefault();
                document.getElementById("global_search_selector").focus();

                //hide acc menu
                document.getElementById("accessibility").style = "display: none;";
            };

        },

        onkeydown: function onkeydown(e) {
            if (e.code === "Tab") {
                var acc_menu = document.getElementById("accessibility");
                acc_menu.style = "display: block;";
            }

        }
    };
});
