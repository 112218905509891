define('app/main',[
    'jquery',
    'app/carousel',
    'app/menu',
    'app/searchFilters',
    'app/forms',
    'app/friendForm',
    'app/ie9fix',
    'app/ie10fix',
    'app/bootstrapselect',
    'app/scroll',
    'app/favorites',
    'app/pagination',
    'app/cookieWall',
    'app/basket',
    'app/accessibility',
    'app/calendar'
], function ($, carousel, menu, searchFilters, forms, friendForm, ie9fix, ie10fix, bootstrapselect,
                scroll, favorites, pagination, cookieWall, basket, accessibility, calendar) {

    'use strict';
    $(document).ready(function () {
        console.log('Loaded app/main.js');
        carousel.init();
        menu.init();
        searchFilters.init();
        forms.init();
        friendForm.init();
        ie9fix.init();
        ie10fix.init();
        bootstrapselect.init();
        scroll.init();
        favorites.init();
        pagination.init();
        basket.init();
        accessibility.init();
        calendar.init();
        cookieWall.init();
    });
});
