define('app/basket',['jquery', 'lodash', 'js.cookie'], function ($, _, Cookies) {
    'use strict';

    return {
        init: function init() {
            var COOKIE_CB = '_b_CB';

            var setBasketHtml = function setBasketHtml(html) {
                return $('.basketDataInfo').html(html);
            };
            var clearCookies = function clearCookies() {
                Cookies.remove(COOKIE_CB);
            };

            try {
                console.log('Start initializing basket');

                var getUrlVars = function getUrlVars() {
                    var vars = [],
                        hash = void 0;
                    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
                    for (var i = 0; i < hashes.length; i++) {
                        hash = hashes[i].split('=');
                        vars.push(hash[0]);
                        vars[hash[0]] = hash[1];
                    }
                    return vars;
                };

                var fetchLatestCallbackURL = function fetchLatestCallbackURL() {
                    var vars = getUrlVars();
                    if (_.includes(vars, 'orderid') && _.includes(vars, 'signature') && _.includes(vars, 'accesskey')) {
                        var newUrl = window.location.href;
                        Cookies.set(COOKIE_CB, { url: newUrl });
                        return newUrl;
                    } else {
                        return _.get(Cookies.getJSON(COOKIE_CB), 'url') || null;
                    }
                };

                var updateBasket = function updateBasket(cbUrl, intervalId) {
                    $.ajax({
                        type: "GET",
                        url: '/api/basketSize/' + encodeURIComponent(cbUrl),
                        dataType: "json",
                        success: function success(result) {
                            var title = '';
                            var nrOfTickets = _.get(result, 'ticketCount');
                            var totalAmount = _.get(result, 'totalCost');
                            if (_.isNumber(nrOfTickets) && _.isNumber(totalAmount) && nrOfTickets > 0 && totalAmount > 0) {
                                nrOfTickets = nrOfTickets.toFixed(0);
                                totalAmount = totalAmount.toFixed(2).replace('.', ',');
                                title = nrOfTickets + ' items - € ' + totalAmount;
                                setBasketHtml(nrOfTickets + "");
                                $('.basketcontents').show();
                                $('.basketlink').attr('title', title);
                            } else {
                                // order is empty/deleted, reset
                                setBasketHtml('');
                                $('.basketcontents').hide();
                                $('.basketlink').attr('title', "Winkelmand");
                                clearCookies();

                                if (!_.isNil(intervalId)) {
                                    // called from setInterval, stop it
                                    console.log('stopping basket update');
                                    clearInterval(intervalId);
                                }
                            }
                        },
                        error: function error(err) {
                            console.warn('error from basket api', err);
                            setBasketHtml('');
                            clearCookies();
                        }
                    });
                };

                // check if new url is available and update CB cookie
                var latestCallbackURL = fetchLatestCallbackURL();

                if (_.isString(latestCallbackURL)) {
                    // call immediately
                    updateBasket(latestCallbackURL);

                    // schedule
                    var intervalId = setInterval(function () {
                        // do ajax call if callback data is present and update DATA cookie
                        // update html if basket data was set
                        updateBasket(latestCallbackURL, intervalId);
                    }, 30000);
                }
            } catch (e) {
                setBasketHtml('');
                clearCookies();
                console.warn('error in basket process', e);
            }
        }
    };
});
